import { makeStyles } from "@material-ui/core/styles";
import charts from "../../images/charts.png";

const AppBarStyle = makeStyles(
  (theme) => ({
    appBar: {
      backgroundColor: "#e6e6e6",
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
      [theme.breakpoints.down(731)]: {
        padding: theme.spacing(2),
      },
    },
    appBarHeader: {
      flex: 1,
      margin: "0px 20px",
      fontFamily: "Charter ITC TT",
      fontSize: "1.8rem",
    },
    // if <1000
    appBarHeaderLessThan1000: {
      flex: 1,
      margin: "0px 10px",
      fontFamily: "Charter ITC TT",
      fontSize: "1.2rem",
    },
    chartsGridLessThan1000: {
      display: "flex",
      alignItems: "end",
      justifyContent: "end",
      backgroundImage: `url(${charts})`,
      backgroundSize: "100% 100%",
      height: "100px",
      width: "150px",
      padding: "20px 40px",
      marginLeft: "40px",
    },

    chartsGrid: {
      display: "flex",
      alignItems: "end",
      justifyContent: "end",
      backgroundImage: `url(${charts})`,
      backgroundSize: "100% 100%",
      height: "150px",
      width: "190px",
      padding: "20px 40px",
      marginLeft: "40px",
    },
    // PwC logo
    imageDimensions: {
      height: "80px",
      width: "auto",
      [theme.breakpoints.down("xs")]: {
        margin: "10px 10px",
        height: "70px",
        width: "auto",
      },
    },
    // PwC logo
    imageDimensions5: {
      display: "none",
    },
    // Jordan Chamber logo
    imageDimensions2: {
      height: "190px",
      width: "auto",
      margin: "20px 10px",
      [theme.breakpoints.down("xs")]: {
        height: "70px",
        width: "auto",
      },
    },
    // Jordan Source logo
    imageDimensions3: {
      height: "100px",
      width: "auto",
      margin: "20px 0px 20px 20px ",
      [theme.breakpoints.down("sm")]: {
        height: "4rem",
      },
    },
    imageDimensions4: {
      height: "40px",
      width: "auto",
      margin: "20px 20px",
      [theme.breakpoints.down("sm")]: {
        height: "4rem",
      },
    },
  }),
  { index: 1 }
);

export default AppBarStyle;
