// for public url
const PublicObjectRestructor = (
  email,
  userName,
  userPosition,
  companyName,
  answersObject
) => {
  const optimizedObj = {
    email: email,
    user_name: userName,
    user_position: userPosition,
    company_name: companyName,
    answers: {
      ...answersObject,
    },
  };
  return optimizedObj;
};

export default PublicObjectRestructor;
