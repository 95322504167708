import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
//Components
import Form from "./Form";
import FormStepper from "./formStepper/FormStepper";
import MessageBox from "./message/MessageBox";
// Context
import { CompanyInfoContext } from "../contexts/CompanyInfoContext";
//Services
import Requests from "../services/Requests";
import ObjectRestructor from "../services/ObjectRestructor";
import PublicObjectRestructor from "../services/PublicUrlObjectRestructor";
// Styles
import { CssBaseline } from "@material-ui/core";
import "../assets/styles/stepper.css";

export default function PublicQuestionnaire() {
  const { companyInfo } = useContext(CompanyInfoContext);
  const [screenDimensions, setScreenDimensions] = useState(window.innerWidth);
  const [activeStep, setActiveStep] = useState(0);
  const [categoriesQuestions, setCategoriesQuestions] = useState([]);
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [checkbox, setCheckbox] = useState([]);
  const [formAnswers, setformAnswers] = useState([]);
  const [errors, setErrors] = useState("");
  const [submitError, setSubmitError] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [questionsErrors, setQuestionsErrors] = useState({});
  const [unAnsweredRequiredQuestions, setUnAnsweredRequiredQuestions] =
    useState({});
  const query = new URLSearchParams(window.location.search);
  const urlId = query.get("public_url_id");
  const email = query.get("email");
  const userName = query.get("user_name");
  const userPosition = query.get("user_position");
  const OrgName = query.get("company_name");
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await Requests.get(
        `public/questions?public_url_id=${urlId}&email=${email}`
      );
      if ("error" in data) {
        setError(data?.error);
        return null;
      }
      setLoading(false);
      setError(null);
      setCategoriesQuestions(data);

      if (data?.length) {
        setRequiredQuestions(
          data[activeStep]?.questions.filter(
            (question) => question.is_required === true
          )
        );
      }
    }

    function handleResize() {
      setScreenDimensions(window.innerWidth);
    }

    fetchData();
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const steps = categoriesQuestions?.map(
    (categoryQuestions) => categoryQuestions.category_name
  );

  function getStepContent() {
    if (
      categoriesQuestions.length > 0 &&
      activeStep < categoriesQuestions.length
    ) {
      return (
        <>
          <Form
            activeStep={activeStep}
            key={categoriesQuestions[activeStep].category_id}
            questions={categoriesQuestions[activeStep]}
            formAnswers={formAnswers}
            setformAnswers={setformAnswers}
            setRequiredQuestions={setRequiredQuestions}
            requiredQuestions={requiredQuestions}
            checkbox={checkbox}
            setCheckbox={setCheckbox}
            errors={errors}
            setQuestionsErrors={setQuestionsErrors}
            questionsErrors={questionsErrors}
            unAnsweredRequiredQuestions={unAnsweredRequiredQuestions}
            setUnAnsweredRequiredQuestions={setUnAnsweredRequiredQuestions}
          />
          {submitError && (
            <p className="text-danger text-center">{submitError}</p>
          )}
        </>
      );
    }
  }
  const checkIfValid = () => {
    let countFilled = 0;
    requiredQuestions.forEach((question) => {
      if (formAnswers[question.id]) {
        countFilled++;
      }
    });
    if (Object.keys(questionsErrors)?.length) {
      setErrors(
        "Please make sure that you have filled out all required fields correctly"
      );
      return null;
    }
    if (countFilled !== requiredQuestions.length) {
      setErrors(
        "Please make sure that you have filled out all required fields"
      );
      return false;
    } else {
      setErrors("");
      setRequiredQuestions(
        categoriesQuestions[activeStep + 1]?.questions?.filter(
          (question) => question.is_required === true
        )
      );
      return true;
    }
  };

  const handleNext = () => {
    if (checkIfValid()) {
      setActiveStep(activeStep + 1);
    }
    screenDimensions > 1024
      ? window.scrollTo(0, 0)
      : window.scrollTo(0, (activeStep + 1) * 80);
  };

  const handleSubmit = async () => {
    if (errors) {
      alert("ERRORRS");
    }
    setLoading(true);
    setSubmitError(null);
    const restructuredPublicObjects = await PublicObjectRestructor(
      email,
      userName,
      userPosition,
      OrgName,
      formAnswers
    );
    console.log("restructures", restructuredPublicObjects);

    const Response = await Requests.post(
      `public/answers?public_url_id=${urlId}`,
      restructuredPublicObjects
    );

    console.log("response====", Response);
    if ("error" in Response) {
      setSubmitError(Response?.error || "Server Error");
      setLoading(false);
      return null;
    }

    setActiveStep(activeStep + 1);
    setLoading(false);
  };

  const handleBack = () => {
    setSubmitError(null);
    setRequiredQuestions(
      categoriesQuestions[activeStep - 1]?.questions?.filter(
        (question) => question.is_required === true
      )
    );
    setActiveStep(activeStep - 1);
    screenDimensions > 1024
      ? window.scrollTo(0, 0)
      : window.scrollTo(0, (activeStep - 1) * 80);
  };

  if (error) {
    return <MessageBox message={error} />;
  }
  if (!categoriesQuestions?.length) {
    return <div />;
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <FormStepper
        email={email}
        urlId={urlId}
        userName={userName}
        userPosition={userPosition}
        OrgName={OrgName}
        activeStep={activeStep}
        steps={steps}
        getStepContent={getStepContent}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        handleBack={handleBack}
        screenDimensions={screenDimensions}
        loading={loading}
        setErrors={setErrors}
      />
    </React.Fragment>
  );
}
