import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import MainContainer from "../components/MainContainer/MainContainer";
import { Grid } from "@material-ui/core";
import Header from "./Banners/Header";
import Footer from "./Banners/Footer";

// Contexts
import { PageTitleContext } from "../contexts/PageTitleContext";
import { CompanyInfoContext } from "../contexts/CompanyInfoContext";

// Styles
import questionnairStyle from "../assets/styles/materialUi/questionnaire";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "../assets/styles/form.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#FFDCA9",
      main: "#d04a02",
      dark: "#ba000d",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ffcc4d",
      main: "#ffb600",
      dark: "#e6a400",
      contrastText: "#000000",
    },
  },
  typography: {
    fontFamily: ["Tajawal", "sans-serif"].join(","),
  },
  overrides: {
    MuiStepIcon: {
      root: {
        fill: "lightGray",
      },
      text: {
        fill: "black",
      },
      active: { fill: "#ffb600" },
      completed: {
        fill: "#ffb600",
      },
    },
  },
});

export default function App() {
  const classes = questionnairStyle();
  const [pageTitle, setPageTitle] = useState(null);
  const [companyInfo, setCompanyInfo] = useState({});

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
          <CompanyInfoContext.Provider value={{ companyInfo, setCompanyInfo }}>
            <div>
              <Header />
              <Grid className={classes.containerHeight}>
                <MainContainer />
              </Grid>
              <Footer classes={classes} />
            </div>
          </CompanyInfoContext.Provider>
        </PageTitleContext.Provider>
      </Router>
    </ThemeProvider>
  );
}
