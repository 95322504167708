import React from "react";
import { Typography, Button, CircularProgress, Grid } from "@material-ui/core";
import questionnairStyle from "../../assets/styles/materialUi/questionnaire";

export default function StepperContent(props) {
  const classes = questionnairStyle();

  return (
    <React.Fragment>
      {props.getStepContent(props.activeStep)}
      <div className={classes.actionsContainer} align="center">
        {props.activeStep === props.steps.length - 1 ? (
          <Grid container justify="center">
            <Typography
              style={{
                color: "#d04a02",
                fontSize: "0.9rem",
                fontWeight: "bold",
              }}
              className="mt-1"
            >
              Please note that answers can't be changed after submitting
            </Typography>
          </Grid>
        ) : (
          <Typography></Typography>
        )}
        {props.activeStep !== 0 && (
          <Button
            onClick={props.handleBack}
            className={classes.buttonSpacing}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={
            props.activeStep === props.steps.length - 1
              ? props.handleSubmit
              : props.handleNext
          }
          className={classes.button}
        >
          {props.loading ? (
            <Grid container justify="center">
              <CircularProgress size={20} className={classes.loader} />
            </Grid>
          ) : props.activeStep === props.steps.length - 1 ? (
            "Save"
          ) : (
            "Next"
          )}
        </Button>
      </div>
    </React.Fragment>
  );
}
