import { makeStyles } from "@material-ui/core/styles";

const CompanyStyle = makeStyles(
  (theme) => ({
    captchaBox: {
      padding: "20px",
      marginTop: theme.spacing(0),
      borderRadius: "7px",
      direction: "ltr",
      backgroundColor: "#f2f2f2",
      "& .MuiInputLabel-formControl": {
        top: 0,
      },
      "& .MuiInputLabel-root": {
        transformOrigin: " top left",
        textAlign: "right",
        color: "grey",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "white",
      },
      "& .MuiInputBases-input": {
        margin: "0px !important",
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
    },
    IntroBox: {
      padding: "25px",
      marginTop: theme.spacing(0),
      borderRadius: "7px",
      direction: "ltr",
      backgroundColor: "#f2f2f2",
      "& .MuiInputLabel-formControl": {
        top: 0,
      },
      "& .MuiInputLabel-root": {
        transformOrigin: " top left",
        textAlign: "right",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "white",
      },
      "& .MuiInputBases-input": {
        margin: "0px !important",
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
    },

    sliderBox: {
      padding: "0px",
      marginTop: theme.spacing(0),
      borderRadius: "7px",
      direction: "ltr",
      backgroundColor: "white",
      "& .MuiInputLabel-formControl": {
        top: 0,
      },
      "& .MuiInputLabel-root": {
        transformOrigin: " top left",
        textAlign: "right",
      },
      "& .MuiFilledInput-root": {
        backgroundColor: "white",
      },
      "& .MuiInputBases-input": {
        margin: "0px !important",
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
    },
  }),
  { index: 1 }
);

export default CompanyStyle;
