import React, { useState, useEffect, useContext } from "react";
import Requests from "../../services/Requests";
// Components
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Grid,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
  Container,
} from "@material-ui/core";
import _ from "lodash";
// Contexts
import { PageTitleContext } from "../../contexts/PageTitleContext";
import { CompanyInfoContext } from "../../contexts/CompanyInfoContext";

//assets
import questionnairStyle from "../../assets/styles/materialUi/questionnaire";
import { ExpandMore } from "@material-ui/icons";

const SurveyReport = () => {
  const classes = questionnairStyle();

  const { setPageTitle } = useContext(PageTitleContext);
  const { companyInfo } = useContext(CompanyInfoContext);
  const [companyAnswers, setCompanyAnswers] = useState({});
  const [reportError, setReportError] = useState(null);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const url_id = query.get("url_id");
    let args = {};

    if (url_id) args["url_id"] = url_id;
    if (companyInfo?.uuid) args["company_id"] = companyInfo?.uuid;

    Requests.post("reporting/company-answers", args).then((response) => {
      if ("error" in response) setReportError("No data to show");
      else {
        setCompanyAnswers(response.data);
        setReportError(null);
      }
    });
  }, [companyInfo, setPageTitle]);

  if (reportError) {
    return (
      <Grid container direction="row-reverse" justify="center">
        <Grid item className={classes.error}>
          {reportError}
        </Grid>
      </Grid>
    );
  } else if (Object.keys(companyAnswers).length)
    return (
      <Container component="main" className={classes.main}>
        <Paper className={classes.paper} variant="outlined">
          <Typography
            component="h1"
            variant="h5"
            className={classes.title}
            align="center"
            gutterBottom
          >
            Survey Answers
          </Typography>

          {_.sortBy(companyAnswers?.categories, [(obj) => obj?.order]).map(
            (category) => {
              return (
                <Accordion
                  expanded={expanded === category.category_id}
                  onChange={handleExpand(category.category_id)}
                  key={category.category_id}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id={category.category_id + "-header"}
                    className={classes.accordionHeader}
                  >
                    <div>
                      <div>
                        <Typography className={classes.secondaryTitle}>
                          {category.category_name}
                        </Typography>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headerCell}>
                            Question
                          </TableCell>
                          <TableCell className={classes.headerCell}>
                            Your answer
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {category?.questions.map((question) => {
                          return (
                            <>
                              <TableRow
                                key={question.question_id + question.option_id}
                              >
                                <TableCell className={classes.bodyCell}>
                                  {question.question_name}
                                </TableCell>
                                <TableCell className={classes.bodyCell}>
                                  {question.option_name
                                    ? question.option_name
                                    : question.value}{" "}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
        </Paper>
      </Container>
    );
  else
    return (
      <Grid container justify="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
};

export default SurveyReport;
