import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
} from "@material-ui/core";
import StepperContent from "./StepperContent";
import StepperMessage from "./StepperMessage";
import questionnairStyle from "../../assets/styles/materialUi/questionnaire";

export default function MobileStepper(props) {
  const classes = questionnairStyle();
  return (
    <div>
      <Stepper activeStep={props.activeStep} orientation="vertical">
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Paper className={classes.paper} variant="outlined">
                {props.activeStep <= props.steps.length && (
                  <StepperContent
                    activeStep={props.activeStep}
                    steps={props.steps}
                    getStepContent={props.getStepContent}
                    handleNext={props.handleNext}
                    handleSubmit={props.handleSubmit}
                    handleBack={props.handleBack}
                    loading={props.loading}
                  />
                )}
              </Paper>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {props.activeStep === props.steps.length && (
        <Paper className={(classes.paper, classes.message)} variant="outlined">
          <StepperMessage
            email={props?.email}
            urlId={props?.urlId}
            userName={props?.userName}
            userPosition={props?.userPosition}
            OrgName={props?.OrgName}
          />
        </Paper>
      )}
    </div>
  );
}
