import React, { useState, useEffect } from "react";

// Components
import { Grid, Typography, AppBar } from "@material-ui/core";

// Styles
import appBarStyle from "../../assets/styles/materialUi/appBar";
// Assets
// import jordanExport from "../../assets/images/Jordan_exports.png";
import jordanSource from "../../assets/images/Jordan_source.png";
import modee from "../../assets/images/MODEE.png";
// import jordanChamberOfIndustry from "../../assets/images/Jordan_chamber_of_industry.png";
import pwc from "../../assets/images/pwc.png";
import wave1 from "../../assets/images/wave1.png";
import wave2 from "../../assets/images/wave2.png";
import wave3 from "../../assets/images/wave3.png";

export default function Header() {
  const appbar = appBarStyle();
  const [screenDimensions, setScreenDimensions] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenDimensions(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppBar
      position="relative"
      color="default"
      elevation={0}
      className={appbar.appBar}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className="px-2"
      >
        {screenDimensions > 1000 && (
          <Grid className="d-flex align-items-center h-80">
            <img className={appbar.imageDimensions} src={pwc} alt="pwc" />
          </Grid>
        )}

        {screenDimensions <= 1000 && (
          <Grid className="d-flex align-items-center h-80">
            <img className={appbar.imageDimensions5} src={pwc} alt="pwc" />
          </Grid>
        )}
        {/* {screenDimensions <= 500 && (
         <Grid className="d-flex align-items-center">
           <img
             className={appbar.imageDimensions4}
             src={modee}
             alt="jordanSource"
           />
         </Grid>
       )}
 
       {screenDimensions > 500 && (
         <Grid className="d-flex align-items-center">
           <img
             className={appbar.imageDimensions3}
             src={modee}
             alt="jordanSource"
           />
         </Grid>
       )} */}
        {screenDimensions > 1000 && (
          <Grid className="d-flex align-items-center justify-content-center h-80 ">
            <Grid className={appbar.chartsGrid}>
              <img height="auto" width="70" src={wave3} alt="wave3" />
            </Grid>
            <Grid className="d-flex flex-column">
              <img height="auto" width="90" src={wave1} alt="wave1" />
              <Typography className={appbar.appBarHeader}>Jordan</Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                className="display-4"
                style={{ fontWeight: "bold", fontSize: "1.2rem" }}
              >
                {" "}
                A Digital & Outsourcing Hub
              </Typography>

              <img
                height="auto"
                width="80"
                src={wave2}
                alt="wave2"
                className="align-self-center"
              />
            </Grid>
          </Grid>
        )}

        {screenDimensions <= 1000 && (
          <Grid className="d-flex align-items-center justify-content-center h-80">
            <Grid className="d-flex align-items-center justify-content-center flex-column">
              <Typography className={appbar.appBarHeaderLessThan1000}>
                Jordan
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                {" "}
                A Digital & Outsourcing Hub
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* {screenDimensions > 1000 && (
         <Grid className="d-flex align-items-center h-100">
           <img className={appbar.imageDimensions} src={pwc} alt="pwc" />
         </Grid>
       )}
 
       {screenDimensions <= 1000 && (
         <Grid className="d-flex align-items-center h-100">
           <img className={appbar.imageDimensions5} src={pwc} alt="pwc" />
         </Grid>
       )} */}

        {screenDimensions <= 500 && (
          <Grid>
            <img
              className={appbar.imageDimensions4}
              src={modee}
              alt="jordanSource"
            />
          </Grid>
        )}

        {screenDimensions > 500 && (
          <Grid>
            <img
              className={appbar.imageDimensions3}
              src={modee}
              alt="jordanSource"
            />
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
}
