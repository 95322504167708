import { createContext } from "react";

export const CompanyInfoContext = createContext({
    id: "",
    name: "",
    url_id: "",
    company_number: "",
    company_type: "",
    email: "",
    mobile: "",
    address: "",
    manager_name: ""
});
