import axios from "axios";
import config from "../config";

class Requests {
    constructor() {
        axios.defaults.baseURL = `${config.baseUrl}/api/`;
    }

    get(url, params = {}) {
        return axios
            .get(url, { params: params })
            .then((res) => res.data)
            .catch((error) => ({
                error: error?.response?.data?.error
            }));
    }

    post(url, data = {}) {
        return axios
            .post(url, data)
            .then((res) => res.data)
            .catch((error) => ({
                error: error.response.data.error
            }));
    }
}

export default new Requests();
