import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// Styles
import questionnairStyle from "../../assets/styles/materialUi/questionnaire";

const MessageBox = ({ message }) => {
    const classes = questionnairStyle();

    return (
        <div className={`container ${classes.invalidPageContainer}`}>
            <Paper
                variant="outlined"
                className={classes.paper}
                square
                color="primary"
            >
                <Typography color="primary" align="center" variant="h4">
                    {message}
                </Typography>
            </Paper>
        </div>
    );
};

export default MessageBox;
