import React from "react";
// Components
import {Grid,Typography,Paper,Container,} from "@material-ui/core";
// Styles
import questionnairStyle from "../assets/styles/materialUi/questionnaire";
import companyStyle from "../assets/styles/materialUi/company";
import "../assets/styles/form.css";

export default function Disclaimer() {
const GeneralClasses = questionnairStyle();
const CompanyClasses = companyStyle();

const IntroMessage= () => {
    return (
    
        <React.Fragment>
  
            <Grid className={CompanyClasses.IntroBox}>
              <div className="d-flex align-items-center " direction="ltr">
                <div className={GeneralClasses.IntroMsgStyle}>
                  <Typography
                    variant="body1"
                    style={{
                      color: "#d04a02",
                      fontWeight: "bolder",
                      fontSize: "1.55em",
                    }}
                  >
                    Dear Sector Representatives,
                  </Typography>
                  <br />
                  <Typography>
                  As a result of the workshop held on 31/1/ 2023 on 
                  “Jordan as a Digital & Outsourcing Hub'',
                  we have designed this survey to better understand and follow
                  up on your comments, suggestions, and feedback. Ultimately, 
                  and based on the sector's business needs and key required 
                  skills and competencies,  an action plan will be initiated. 

                    <br />
                    <br />
                    The survey is divided into{" "}
                    <strong style={{ color: "#6c757d" }}>6 </strong>
                    main sections with the key considerations
                    out of our meeting and would need around 
                    <strong style={{ color: "#6c757d" }}> 5-10 minutes </strong>
                    to complete.
                
                  Your input is valuable and will help design and 
                  customize our future action plan on the outcomes 
                  to better serve the sector’s needs, growth, 
                  scalability and sustainability.
                  <br />{" "}
                    <br />
                    If you have any questions or require any information, please feel free to get in touch with us
                    In collaboration with the MoDEE, 
                    this survey has been developed with 
                    kind support from PwC . The information 
                    and analysis resulting from the survey will
                    be shared with all participants and will
                      be made publicly available after anonymization.
                    <br />
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#6c757d",
                      fontWeight: "bolder",
                      fontSize: "1.2em",
                   
                      marginTop: "10px",
                    }}
                  >
                    Thank you!
                  </Typography>
                </div>
              </div>
            </Grid>

      
        </React.Fragment>

    );

};

  return (
    <React.Fragment>
      <Grid >
        {IntroMessage()}
      </Grid>
    </React.Fragment>
  );
}
