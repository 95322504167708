import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControl,
  Radio,
  FormHelperText,
  Slider,
  Divider,
} from "@material-ui/core";
import "../assets/styles/form.css";
import questionnairStyle from "../assets/styles/materialUi/questionnaire";

export default function Form(props) {
  const questions = props?.questions?.questions || [];
  const classes = questionnairStyle();

  const handelInput = (event, question) => {
    let options = [event.target.value];
    saveAnswer(null, question, options);
  };
  const handelScale = (event, question, scaleValue) => {
    saveAnswer(null, question, null, scaleValue);
  };

  const handleCheckbox = (event, question) => {
    let tempCheckbox = {
      ...props.checkbox,
    };

    if (question?.id in tempCheckbox) {
      if (question?.validations?.minNumberOfCheckBoxes) {
        if (
          tempCheckbox[question.id]?.optionIds?.length + 1 <
          question?.validations?.minNumberOfCheckBoxes
        ) {
          props?.setQuestionsErrors((prevVal) => {
            let temp = { ...prevVal };
            temp[question?.id] = "Please choose at least 3 options";
            return temp;
          });
        } else {
          props?.setQuestionsErrors((prevVal) => {
            let temp = { ...prevVal };
            delete temp[question?.id];
            return temp;
          });
        }
      }

      var index = tempCheckbox[question.id].optionIds.indexOf(event.target.id);
      if (index !== -1) {
        tempCheckbox[question.id].optionIds.splice(index, 1);
      } else {
        tempCheckbox[question.id].optionIds.push(event.target.id);
      }
      props.setCheckbox(tempCheckbox);

      saveAnswer(null, question, tempCheckbox[question.id].optionIds);
    } else {
      tempCheckbox[question.id] = {
        optionIds: [event.target.id],
      };
      if (question?.validations?.minNumberOfCheckBoxes) {
        if (
          tempCheckbox[question.id]?.optionIds?.length + 1 <
          question?.validations?.minNumberOfCheckBoxes
        ) {
          props?.setQuestionsErrors((prevVal) => {
            let temp = { ...prevVal };
            temp[question?.id] = "Please choose at least 3 options";
            return temp;
          });
        } else {
          props?.setQuestionsErrors((prevVal) => {
            let temp = { ...prevVal };
            delete temp[question?.id];
            return temp;
          });
        }
      }
      props.setCheckbox(tempCheckbox);
      saveAnswer(null, question, tempCheckbox[question.id]?.optionIds);
    }
  };

  const handleNumber = (event, question) => {
    const value = event.target.value;
    var regex1 = /^-?[0-9]*$/;
    if (!value.match(regex1)) {
      return null;
    }
    if (!question?.validations?.length) {
      saveAnswer(event, question);
      return null;
    }
    const min = question?.validations[0]?.min;
    const max = question?.validations[0]?.max;
    if (value >= min && value <= max) {
      saveAnswer(event, question);
      saveAnswer(event, question);
    }
  };

  const saveAnswer = (event, question, options = null, scaleValue = null) => {
    let tempAnswers = {
      ...props.formAnswers,
      [question.id]: {
        options: options || [],
        value: event?.target.value || scaleValue || null,
      },
    };

    if (
      (tempAnswers[question.id] && event?.target.value === "") ||
      options?.length === 0
    ) {
      delete tempAnswers[question.id];
    }
    props.setformAnswers(tempAnswers);
  };

  const removeAnswer = (question) => {
    props.setformAnswers((answers) => {
      delete answers[question.id];
      return answers;
    });
  };

  const checkDep = (question) => {
    let dependencies = question?.dependency;
    let flag = false;
    if (!dependencies?.length) return true;

    dependencies.forEach((dependency) => {
      let answered_value = props?.formAnswers[dependency?.question_id]?.value;
      let answered_options =
        props?.formAnswers[dependency?.question_id]?.options;
      if (
        answered_value === dependency?.expected_value ||
        answered_options?.includes(dependency?.option_id)
      ) {
        if (!props.requiredQuestions.includes(question)) {
          props.setRequiredQuestions((prevState) => {
            prevState.push(question);
            return prevState;
          });
        }
        flag = true;
      } else if (props.requiredQuestions.includes(question)) {
        props.setRequiredQuestions((prevState) =>
          prevState.filter((q) => q !== question)
        );
        props.setCheckbox((answers) => {
          delete answers[question.id];
          return answers;
        });
        removeAnswer(question);
      }
    });
    return flag;
  };

  const getQuestionPadding = (questionLabel) => {
    return questionLabel?.length > 55 ? 10 : 8;
  };

  const renderedQuestions = () => {
    let questionIndex = 0;

    return questions.map((question) => {
      const checkDepResult = checkDep(question);
      if (!checkDepResult) return <div />;

      questionIndex = questionIndex + 1;
      return (
        <Grid key={question.id} container item spacing={1}>
          <Grid item xs={11}>
            {question.type === "text" && (
              <TextField
                InputProps={{
                  style: {
                    padding: getQuestionPadding(question.text),
                  },
                }}
                // style={{ padding: `${getQuestionPadding(question.text)}px 10px 5px 10px !important` }}
                // required
                maxrows={2}
                value={props?.formAnswers[question.id]?.value || ""}
                label={question.text}
                fullWidth
                multiline
                variant="standard"
                // xs={{padding:getQuestionPadding(question.text)}}
                onChange={(event) => saveAnswer(event, question)}
              />
            )}
            {question.type === "number" && (
              <TextField
                required
                InputProps={{
                  style: {
                    padding: getQuestionPadding(question.text),
                  },
                }}
                value={props?.formAnswers[question.id]?.value || ""}
                label={question.text}
                fullWidth
                variant="standard"
                xs={{ padding: getQuestionPadding(question.text) }}
                onChange={(event) => handleNumber(event, question)}
              />
            )}

            {question.type === "select" && (
              <FormGroup>
                <InputLabel id={question.text}>{question.text}</InputLabel>
                <Select
                  required
                  labelId={question.text}
                  value={props.formAnswers[question.id]?.options[0] || ""}
                  fullWidth
                  variant="standard"
                  onChange={(event) => handelInput(event, question)}
                >
                  {question.options.map((option) => {
                    return (
                      <MenuItem key={question.id + option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            )}

            {question.type === "checkbox" && (
              <FormGroup>
                <FormLabel component="legend">{question.text}</FormLabel>
                {question.options.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.id}
                      control={
                        <Checkbox
                          checked={
                            props.formAnswers[question.id]?.options?.includes(
                              option.id
                            ) || false
                          }
                          onChange={(event) => handleCheckbox(event, question)}
                          value={option.name}
                          id={option.id}
                        />
                      }
                      label={option.name}
                    />
                  );
                })}

                {props?.questionsErrors[question?.id] && (
                  <FormHelperText>
                    {props?.questionsErrors[question?.id]}
                  </FormHelperText>
                )}
              </FormGroup>
            )}

            {(question.type === "radio" || question.type === "bool") && (
              <FormControl component="fieldset">
                <FormLabel component="legend">{question.text}</FormLabel>
                <RadioGroup
                  value={props.formAnswers[question.id]?.options[0] || ""}
                  onChange={(event) => handelInput(event, question)}
                >
                  {question.options.map((option) => {
                    return (
                      <FormControlLabel
                        key={option.id}
                        value={option.id}
                        control={<Radio />}
                        label={option.name}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            )}
            {question.type === "range" && (
              <FormGroup>
                <Typography id="discrete-slider-always">
                  {question.text}
                </Typography>
                <Slider
                  value={
                    (props?.formAnswers[question.id] &&
                      props?.formAnswers[question.id].value) ||
                    1
                  }
                  aria-labelledby="discrete-slider-always"
                  min={question?.validations[0]?.min || 1}
                  max={question?.validations[0]?.max || 5}
                  step={1}
                  marks={[
                    {
                      value: 1,
                      label: 1,
                    },
                    {
                      value: 2,
                      label: 2,
                    },
                    {
                      value: 3,
                      label: 3,
                    },
                    {
                      value: 4,
                      label: 4,
                    },
                    {
                      value: 5,
                      label: 5,
                    },
                  ]}
                  onChange={(event, value) =>
                    handelScale(event, question, value)
                  }
                  valueLabelDisplay={
                    props?.formAnswers[question.id] &&
                    props?.formAnswers[question.id].value
                      ? "on"
                      : "off"
                  }
                />
              </FormGroup>
            )}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <React.Fragment>
     
      <Typography
        component="h1"
        variant="h5"
        className={classes.title}
        align="center"
        gutterBottom
      >
        {props.questions.category_name}
      </Typography>
      <Typography variant="h5" className={classes.description}>
        {props.questions.category_description}
      </Typography>
      {props?.activeStep === 0 &&  (
      <>
      <Typography variant="caption" className={classes.Example}>
        {" "}
        <br />
        Action : Steering committee to meet on regular basis with listed KPIs
        <br />
        Responsibility : Government - MODEE <br />
        Priority : Strategic / Long term
      </Typography>

      <Typography
        style={{
          fontWeight: "bolder",
          color: "#5F5F5F",
          fontSize: "1rem",
          marginTop: "13px",
          marginBottom: "10px",
        }}
      >
        Please share your actionable ideas below:
      </Typography>
      </>
      )}


      {props.errors && (
        <Typography
          className={classes.errorMessage}
          sx={{ color: "red" }}
          align="center"
          gutterBottom
        >
          {props.errors}
        </Typography>
      )}
      <Grid container spacing={3}>
        {renderedQuestions()}
      </Grid>
    </React.Fragment>
  );
}
