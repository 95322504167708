import React from "react";
import WebStepper from "./WebStepper";
import MobileStepper from "./MobileStepper";

export default function FormStepper(props) {
  return (
    <div>
      {props.screenDimensions > 1024 && (
        <WebStepper
          email={props?.email}
          urlId={props?.urlId}
          userName={props?.userName}
          userPosition={props?.userPosition}
          OrgName={props?.OrgName}
          activeStep={props.activeStep}
          steps={props.steps}
          getStepContent={props.getStepContent}
          handleNext={props.handleNext}
          handleSubmit={props.handleSubmit}
          handleBack={props.handleBack}
          loading={props.loading}
        />
      )}
      {props.screenDimensions <= 1024 && (
        <MobileStepper
          email={props?.email}
          urlId={props?.urlId}
          userName={props?.userName}
          userPosition={props?.userPosition}
          OrgName={props?.OrgName}
          activeStep={props.activeStep}
          steps={props.steps}
          getStepContent={props.getStepContent}
          handleNext={props.handleNext}
          handleSubmit={props.handleSubmit}
          handleBack={props.handleBack}
          loading={props.loading}
        />
      )}
    </div>
  );
}
