const ObjectRestructor = (answersObject, companyId) => {
    const optimizedObj = {
        company_id: companyId,
        answers: {
            ...answersObject
        }
    };
    return optimizedObj;
};

export default ObjectRestructor;
