import { makeStyles } from "@material-ui/core/styles";

const questionnairStyle = makeStyles(
  (theme) => ({
    footer: {
      top: "auto",
      bottom: 0,
      direction: "ltr",
      backgroundColor: "transparent",
      boxShadow: "none",
      [theme.breakpoints.down(1024)]: {
        backgroundColor: "white",
      },
    },
    copyRight: {
      color: "black",
      position: "absolute",
      left: 30,
      bottom: 30,
    },
    containerHeight: {
      minHeight: "40vh",
    },
    main: {
      minHeight: "50vh",
      direction: "ltr",
      width: "55%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      boxShadow: "5px 5px 10px #888888",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(7),
        direction: "ltr",
      },
    },
    InfoPaper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      boxShadow: "5px 5px 10px #888888",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(4),
        direction: "ltr",
      },
    },
    slidesPaper: {
      width: "55%",
      minHeight: "50vh",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      boxShadow: "5px 5px 10px #888888",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(7),
        direction: "ltr",
      },
    },

    stepper: {
      padding: theme.spacing(3, 4, 5, 4),
      textAlign: "right",
      direction: "ltr",
      backgroundColor: "transparent",
    },
    button: {
      outline: "none",
      color: "white",
      border: "none",
      fontSize: "0.9rem",
      fontWeight: "bolder",
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      // [theme.breakpoints.up("md")]: {
      //   padding: "10px 30px 10px 30px",
      // },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.7rem",
      },
      "&:focus": {
        outline: "none",
      },
    },
    confirmButton: {
      outline: "none",
      color: "white",
      border: "none",
      fontSize: "0.9rem",
      fontWeight: "bolder",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.7rem",
      },
      "&:focus": {
        outline: "none",
      },
    },
    // buttonSpacing: {
    //   fontSize: "1.2rem",
    //   fontWeight: "bolder",
    //   marginTop: theme.spacing(3),
    //   marginLeft: theme.spacing(1),
    //   justifyContent: "flex-end!important",
    //   [theme.breakpoints.up("md")]: {
    //     padding: "10px 30px 10px 30px",
    //   },
    //   "&:focus": {
    //     outline: "none",
    //   },
    // },

    buttonSpacing: {
      fontSize: "0.9rem",
      fontWeight: "bolder",
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      justifyContent: "flex-end!important",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.7rem",
      },
      "&:focus": {
        outline: "none",
      },
    },

    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontWeight: "bolder",
      marginBottom: "30px",
      fontSize: "1.55em",
      color: "#d04a02",
    },
    description: {
      fontSize: "1.4em",
      color: "#6c757d",
    },
    Example: {
      marginBottom: "20px",
      fontSize: "1.2em",
      fontWeight: "bold",
      color: "#6c757d",
    },

    secondaryTitle: {
      fontWeight: "bold",
      fontSize: "1.2em",
    },
    invalidPageContainer: {
      minHeight: "53vh",
      [theme.breakpoints.down("md")]: {
        minHeight: "70vh",
      },
    },
    message: {
      margin: "20px",
      padding: "30px",
    },
    messageHeader: {
      textAlign: "center",
    },
    messageBody: {
      textAlign: "center",
    },
    messageLink: {
      color: "goldenrod",
      marginRight: "5px",
      marginLeft: "5px",
      fontWeight: "bold",
    },
    errorMessage: {
      color: "red",
      textAlign: "center",
    },

    boxAnswers: {
      fontFamily: "Tajawal",
      fontWeight: "bolder",
    },
    separatingLine: {
      margin: "0",
    },
    accordionHeader: {
      backgroundColor: "#f5f5f5",
      marginBottom: theme.spacing(1),
      color: "#d04a02",
    },
    bodyCell: {
      textAlign: "left",
    },
    headerCell: {
      fontWeight: "bold",
      fontSize: "1.1rem",
      textAlign: "left",
    },
    totalWeight: {
      textAlign: "left",
      marginTop: theme.spacing(3),
    },
    loader: {
      color: "white",
    },
    table: {
      tableLayout: "fixed",
    },
    termsOfUseContent: {
      width: "100%",
      height: "420px",
      overflowX: "hidden",
      overflowY: "scroll",
      paddingRight: "20px",
    },
    numberingStyle: {
      color: "#d04a02",
      fontSize: "1.2rem",
    },
    disclaimerHeadersStyle: {
      color: "#d04a02",
      fontSize: "1.2rem",
      fontWeight: "bolder",
    },
    disclaimerContentStyle: {
      color: "grey",
      fontSize: "1.2rem",
      fontWeight: "bolder",
    },

    IntroContentStyle: {
      fontSize: "1.2rem",
    },
    IntroMsgStyle: {
      width: "100%",
      height: "250px",
      overflowX: "hidden",
      overflowY: "scroll",
      paddingRight: "20px",
    },
    TermsOfUseLink: {
      color: "#d04a02",
      marginRight: "5px",
      marginLeft: "5px",
      fontWeight: "bold",
    },
    spanStyle: {
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#d04a02",
      fontSize: "1.4rem",
      fontWeight: "bolder",
    },
    divStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "100% 100%",
      borderRadius: "7px",
      height: "55vh",
      width: "100%",
    },

    direction: "ltr",
  }),
  { index: 1 }
);

export default questionnairStyle;
