import React from "react";

// Components
import { Grid, AppBar, Typography } from "@material-ui/core";

// Assets
import footer from "../../assets/images/footer.png";

export default function Footer({ classes }) {
  return (
    <AppBar position="relative" color="primary" className={classes.footer}>
      <Grid
        style={{
          backgroundImage: `url(${footer})`,
          backgroundRepeat: "repeat-x",
          backgroundSize: "60% 100%",
          minHeight: "200px",
        }}
      ></Grid>
      <Typography className={classes.copyRight}>
        <span> PwC 2023, </span>
        <span> All rights reserved </span>
        <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
      </Typography>
    </AppBar>
  );
}
