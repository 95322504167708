import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import questionnairStyle from "../../assets/styles/materialUi/questionnaire";
// import { Link } from "react-router-dom";
 
export default function StepperMessage({urlId, email}) {
 const classes = questionnairStyle();
 
 return (
   <React.Fragment>
     <Typography className={classes.messageHeader} variant="h4" gutterBottom>
     Thank you!
 
     </Typography>
     <Typography className={classes.messageBody} variant="subtitle1">
     Your answers have been successfully submitted
     </Typography>
 
     <Typography className={classes.messageBody} variant="subtitle1">
      <Link to={`/public-survey-report?public_url_id=${urlId}&email=${email}`}  className={classes.messageLink}>
         Click Here
       </Link>
       to view your answers.
       
     </Typography>
     <Typography className={classes.messageBody} variant="subtitle1">
      To exit please close your browser.
     </Typography>
   </React.Fragment>
 );
}
 

