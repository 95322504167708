import React from "react";
import { Switch, Route } from "react-router-dom";

// Components
import Questionnaire from "../Questionnaire";
import Company from "../Company";
import InvalidPage from "../invalidPage/InvalidPage";
import SurveyReport from "../SurveyReport/SurveyReport";
import PublicSurveyReport from "../SurveyReport/PublicSurveyReport";
import Disclaimer from "../Disclaimer";
import Slides from "../Slides";
import PublicQuestionnaire from "../PublicQuestionnaire";
import PublicCompany from "../PublicCompany";
import PublicSlides from "../PublicSlides";
import TermsOfUse from "../TermsOfUse";

const MainContainer = () => {
  return (
    <div>
      <Switch>
        {/* <Route path="/" exact component={() => <Slides />} /> */}
        <Route path="/disclaimer" component={() => <Disclaimer />} />
        <Route path="/survey-report" component={() => <SurveyReport />} />
        <Route path="/terms-of-use" component={() => <TermsOfUse />} />
        <Route path="/questionnaire" component={() => <Questionnaire />} />
        <Route path="/company" component={() => <Company />} />
        <Route
          path="/public-survey-report"
          component={() => <PublicSurveyReport />}
        />
        <Route path="/public-company" component={() => <PublicCompany />} />
        <Route
          path="/public-questionnaire"
          component={() => <PublicQuestionnaire />}
        />
        <Route path="/" exact component={() => <PublicSlides />} />
        <Route path="/invalid-page" component={InvalidPage} />
        <Route path="*" component={InvalidPage} />
      </Switch>
    </div>
  );
};

export default MainContainer;
