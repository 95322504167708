import React from "react";
import { Stepper, Step, StepLabel, Container, Paper } from "@material-ui/core";
import StepperContent from "./StepperContent";
import StepperMessage from "./StepperMessage";
import questionnairStyle from "../../assets/styles/materialUi/questionnaire";

export default function WebStepper(props) {
  const classes = questionnairStyle();

  return (
    <div>
      <Stepper activeStep={props.activeStep} className={classes.stepper}>
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container component="main" className={classes.main}>
        <Paper className={classes.paper} variant="outlined">
          <React.Fragment>
            {props.activeStep === props.steps.length ? (
              <StepperMessage
                email={props?.email}
                urlId={props?.urlId}
                userName={props?.userName}
                userPosition={props?.userPosition}
                OrgName={props?.OrgName}
              />
            ) : (
              <StepperContent
                activeStep={props.activeStep}
                steps={props.steps}
                getStepContent={props.getStepContent}
                handleNext={props.handleNext}
                handleSubmit={props.handleSubmit}
                handleBack={props.handleBack}
                loading={props.loading}
              />
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </div>
  );
}
