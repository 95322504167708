import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
// Components
import {
  Grid,
  Typography,
  Button,
  Paper,
  Container,
  Divider,
} from "@material-ui/core";
// Context
import { CompanyInfoContext } from "../contexts/CompanyInfoContext";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
// Icons
// Styles
import questionnaireStyle from "../assets/styles/materialUi/questionnaire";
import companyStyle from "../assets/styles/materialUi/company";
import "../assets/styles/form.css";
import "../assets/styles/slides.css";
import slideOne from "../assets/images/slides/jordan-source-1.png";
import slideTwo from "../assets/images/slides/IntroMessage.png";
import slideThree from "../assets/images/slides/sllide3.png";
import slideFour from "../assets/images/slides/slide4.png";
export default function PublicSlides() {
  const GeneralClasses = questionnaireStyle();
  const CompanyClasses = companyStyle();
  // Hooks
  let history = useHistory();

  const slideImages = [
    {
      url: slideOne,
      caption: "1",
    },
    {
      url: slideTwo,
      caption: "2",
    },
    {
      url: slideThree,
      caption: "3",
    },
    {
      url: slideFour,
      caption: "4",
    },
  ];
  const NextButton = () => {
    const query = new URLSearchParams(window.location.search);
    const urlId = query.get("public_url_id");
    history.push("/public-company?public_url_id=" + urlId);
  };
  const SlidesBox = () => {
    return (
      <React.Fragment>
        <Grid className={CompanyClasses.sliderBox}>
          <div className="slide-container">
            <Slide duration={20000}>
              {slideImages.map((slideImage, index) => (
                <div key={index}>
                  <div
                    className={GeneralClasses.divStyle}
                    style={{
                      backgroundImage: `url(${slideImage.url})`,
                    }}
                  ></div>
                  <span className={GeneralClasses.spanStyle}>
                    {slideImage.caption}
                  </span>
                </div>
              ))}
            </Slide>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-start">
            <Button
              variant="contained"
              color="primary"
              className={`${GeneralClasses.confirmButton} `}
              onClick={NextButton}
            >
              Proceed to survey
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Container component="main" className={GeneralClasses.slidesPaper}>
        {SlidesBox()}
      </Container>
    </React.Fragment>
  );
}
