import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Components
import { Grid, Typography, Button, Paper, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import PublicQuestionnaire from "./PublicQuestionnaire";
import IntroMessage from "./IntroMessage";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
// Context
import { CompanyInfoContext } from "../contexts/CompanyInfoContext";
// Styles
import questionnairStyle from "../assets/styles/materialUi/questionnaire";
import companyStyle from "../assets/styles/materialUi/company";
import "../assets/styles/form.css";

export default function PublicCompany() {
  const GeneralClasses = questionnairStyle();
  const CompanyClasses = companyStyle();
  const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
  const [captchaTxt, setCaptchaTxt] = useState("");
  const [userEmail, setUserEmail] = useState(
    sessionStorage.getItem("userEmail") || ""
  );
  const [userName, setUserName] = useState(
    sessionStorage.getItem("userName") || ""
  );
  const [OrgName, setOrgName] = useState(
    sessionStorage.getItem("OrgName") || ""
  );
  const [userPosition, setUserPosition] = useState(
    sessionStorage.getItem("userPosition") || ""
  );
  const [isSurveyAnswered, setIsSurveyAnswered] = useState(
    companyInfo?.answered_survey
  );
  const [message, setMessage] = useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  let history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const urlId = query.get("public_url_id");

  useEffect(() => {
    loadCaptchaEnginge(6, "#dedede");
  }, [isSurveyAnswered]);

  const checkCaptcha = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userEmail)) {
      setEmailValidationMessage("");
      if (validateCaptcha(captchaTxt)) {
        history.push(
          "/public-questionnaire?public_url_id=" +
            urlId +
            "&email=" +
            userEmail +
            "&user_name=" +
            userName +
            "&user_position=" +
            userPosition +
            "&company_name=" +
            OrgName
        );
        setMessage("");
      } else setMessage("The entered code does not match");
    } else {
      setEmailValidationMessage("Enter a valid Email");
    }
  };

  const renderCompanyInfo = () => {
    return (
      <React.Fragment>
        <Typography
          color="primary"
          style={{ fontWeight: "600", fontSize: "1.4rem" }}
        >
          {" "}
          Some information before we start the survey :
        </Typography>

        <Grid className={`${CompanyClasses.captchaBox} mt-4 `}>
          <div className="d-flex align-items-center" direction="ltr">
            <Typography
              color="primary"
              style={{ fontWeight: "600", fontSize: "1.1rem" }}
            >
              {" "}
              Please enter your full name:
            </Typography>
          </div>
          <TextField
            id="standard-basic"
            label="Full name"
            variant="filled"
            value={userName}
            onChange={(event) => {
              setUserName(event.target.value);
              sessionStorage.setItem("userName", event.target.value);
            }}
            className="mb-4 w-100"
          />

          <div className="d-flex align-items-center" direction="ltr">
            <Typography
              color="primary"
              style={{ fontWeight: "600", fontSize: "1.1rem" }}
            >
              {" "}
              Organization name:
            </Typography>
          </div>
          <TextField
            id="standard-basic"
            label="Organization name"
            variant="filled"
            value={OrgName}
            onChange={(event) => {
              setOrgName(event.target.value);
              sessionStorage.setItem("OrgName", event.target.value);
            }}
            className="mb-4 w-100"
          />
          <div className="d-flex align-items-center" direction="ltr">
            <Typography
              color="primary"
              style={{ fontWeight: "600", fontSize: "1.1rem" }}
            >
              {" "}
              Position:
            </Typography>
          </div>
          <TextField
            id="standard-basic"
            label="Position name"
            variant="filled"
            value={userPosition}
            onChange={(event) => {
              setUserPosition(event.target.value);
              sessionStorage.setItem("userPosition", event.target.value);
            }}
            className="mb-4 w-100"
          />

          <div className="d-flex align-items-center" direction="ltr">
            <Typography
              color="primary"
              style={{ fontWeight: "600", fontSize: "1.1rem" }}
            >
              {" "}
              Please enter your email:
            </Typography>
          </div>
          <TextField
            id="standard-basic"
            label="Enter email"
            variant="filled"
            style={{ borderRadius: "50px" }}
            value={userEmail}
            onChange={(event) => {
              setUserEmail(event.target.value);
              sessionStorage.setItem("userEmail", event.target.value);
            }}
            className="mb-4 w-100"
            helperText={emailValidationMessage}
          />

          <div className="d-flex align-items-center" direction="ltr">
            <Typography
              color="primary"
              style={{ fontWeight: "600", fontSize: "1.1rem" }}
            >
              Verification code:
            </Typography>
            <LoadCanvasTemplateNoReload reloadText="New verification code:" />
          </div>
          <TextField
            id="standard-basic"
            label="Enter Text"
            variant="filled"
            value={captchaTxt}
            onChange={(event) => {
              setCaptchaTxt(event.target.value);
            }}
            className="mt-2 w-100"
            helperText={message}
          />

          <Typography
            style={{
              color: "grey",
              fontSize: "0.9rem",
            }}
            className="mt-1"
          >
            By clicking the "Next" button below, I hereby agree to and accept
            the
            <Link
              to={`/terms-of-use?public_url_id=${urlId}`}
              className={GeneralClasses.TermsOfUseLink}
            >
              {" "}
              Terms and Conditions.
            </Link>
          </Typography>
          <div className="w-100 d-flex justify-content-end align-items-start">
            <Button
              variant="contained"
              color="primary"
              className={`${GeneralClasses.button} `}
              onClick={checkCaptcha}
            >
              Start
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Container component="main" className={GeneralClasses.main}>
        <Paper className={GeneralClasses.InfoPaper} variant="outlined">
          {renderCompanyInfo()}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
