import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
// Components
import { Grid, Typography, Button, Paper, Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import TermsOfUse from "./TermsOfUse";
import IntroMessage from "./IntroMessage";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
// Context
import { CompanyInfoContext } from "../contexts/CompanyInfoContext";
// Styles
import questionnairStyle from "../assets/styles/materialUi/questionnaire";
import companyStyle from "../assets/styles/materialUi/company";
import "../assets/styles/form.css";

export default function Company() {
  const GeneralClasses = questionnairStyle();
  const CompanyClasses = companyStyle();
  const { companyInfo, setCompanyInfo } = useContext(CompanyInfoContext);
  const [captchaTxt, setCaptchaTxt] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isSurveyAnswered, setIsSurveyAnswered] = useState(
    companyInfo?.answered_survey
  );
  const [message, setMessage] = useState("");
  let history = useHistory();

  useEffect(() => {
    if (isSurveyAnswered === false) loadCaptchaEnginge(6, "#dedede");
  }, [isSurveyAnswered]);
  const checkCaptcha = () => {
    if (validateCaptcha(captchaTxt)) {
      history.push("/questionnaire");
      setMessage("");
    } else {
      setMessage("The entered code does not match");
    }
  };
  const companyInfoKeys = {
    //  name: "Company Name ",
  };

  const companyInfoFields = Object.keys(companyInfo).map((key) => {
    if (companyInfoKeys[key]) {
      return (
        <Grid key={key} item xs={12}>
          <Typography
            color="primary"
            className="d-inline-block ml-2 "
            direction="ltr"
            textalign="left"
            component="h1"
            variant="h5"
          >
            {companyInfo[key]}
            {`   `}
          </Typography>
          <br />
        </Grid>
      );
    } else return <Grid key={key}></Grid>;
  });

  const renderCompanyInfo = () => {
    return (
      <React.Fragment>
        <IntroMessage />

        <Grid className={`${CompanyClasses.captchaBox} mt-4 `}>
          <div className="mb-2">
            <div className="d-flex align-items-center" direction="ltr">
              <Typography style={{ fontWeight: "600", fontSize: "1.1rem" }}>
                {" "}
                Please enter your email:
              </Typography>
            </div>
            <TextField
              id="standard-basic"
              label="Email"
              variant="filled"
              value={userEmail}
              onChange={(event) => {
                setUserEmail(event.target.value);
              }}
              className="mb-4 w-100"
            />
          </div>
          <div className="d-flex align-items-center" direction="ltr">
            <Typography style={{ fontWeight: "600", fontSize: "1.1rem" }}>
              {" "}
              Verification code:
            </Typography>
            <LoadCanvasTemplateNoReload reloadText="New verification code:" />
          </div>
          <TextField
            id="standard-basic"
            label="Enter Text"
            variant="filled"
            value={captchaTxt}
            onChange={(event) => {
              setCaptchaTxt(event.target.value);
            }}
            className="mt-2 w-100"
            helperText={message}
          />
        </Grid>
        <TermsOfUse />
        <div className="w-100 d-flex justify-content-end align-items-start">
          <Button
            variant="contained"
            color="primary"
            className={`${GeneralClasses.button} `}
            onClick={checkCaptcha}
          >
            Next
          </Button>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Container component="main" className={GeneralClasses.main}>
        <Paper className={GeneralClasses.paper} variant="outlined">
          {renderCompanyInfo()}
        </Paper>
      </Container>
    </React.Fragment>
  );
}
